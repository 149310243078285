html {
    font-family: "Roboto", sans-serif;
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

@font-face {
    font-family: "Roboto";
    src: local(Roboto Thin), url(./assets/fonts/roboto/Roboto-Thin.woff2) format("woff2"), url(./assets/fonts/roboto/Roboto-Thin.woff) format("woff");
    font-weight: 100;
}

@font-face {
    font-family: "Roboto";
    src: local(Roboto Light), url(./assets/fonts/roboto/Roboto-Light.woff2) format("woff2"), url(./assets/fonts/roboto/Roboto-Light.woff) format("woff");
    font-weight: 300;
}

@font-face {
    font-family: "Roboto";
    src: local(Roboto Regular), url(./assets/fonts/roboto/Roboto-Regular.woff2) format("woff2"), url(./assets/fonts/roboto/Roboto-Regular.woff) format("woff");
    font-weight: 400;
}

@font-face {
    font-family: "Roboto";
    src: local(Roboto Medium), url(./assets/fonts/roboto/Roboto-Medium.woff2) format("woff2"), url(./assets/fonts/roboto/Roboto-Medium.woff) format("woff");
    font-weight: 500;
}

@font-face {
    font-family: "Roboto";
    src: local(Roboto Bold), url(./assets/fonts/roboto/Roboto-Bold.woff2) format("woff2"), url(./assets/fonts/roboto/Roboto-Bold.woff) format("woff");
    font-weight: 700;
}